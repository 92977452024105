@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.App {
  text-align: center;
  font-family: 'Jost', sans-serif;
}

.App-header {
  background-color: #8db399;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ffffff;
}

.swiper {
  width: 600px;
  height: 300px;
}


.mySwiper {
  width: 100vw;
  height: 50vh;
}

